import separator from '../assets/images/separator.svg';
import './Home.scss';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';
function Home() {
  useEffect(() => {
    // Fonction pour masquer l'élément
    const hideWidgetLink = () => {
      const widgetLink = document.querySelector(
        '.elfsight-app-5e13ad4f-52d4-43fc-9ac6-b36b1c871ec8 a'
      );
      if (widgetLink) {
        widgetLink.style.display = 'none';
      }
    };

    // Utiliser setTimeout pour s'assurer que le widget est chargé
    const timeoutId = setTimeout(hideWidgetLink, 1000); // Ajustez le délai si nécessaire

    // Nettoyage de l'effet
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div className="Home">
      <div className="Home__contact">
        <Link to="/contact">→ Prise de rendez-vous ←</Link>
      </div>
      <div className="Home__content">
        <div className="Home__content--text">
          <p>Votre voiture, notre passion.</p>
          <p>Chez GSI Automeca, nous donnons vie à vos projets.</p>
          <span id="calcul"></span>
        </div>
        <ElfsightWidget
          widgetId={'5e13ad4f-52d4-43fc-9ac6-b36b1c871ec8'}
          lazy
        />
        ;
        <div className="Home__content--text">
          <h1>Qui sommes nous ?</h1>
          <p>
            Bienvenue chez GSI AutoMeca votre spécialiste de la réparation
            automobile.{' '}
          </p>
          <p>
            Nous sommes passionnés par l'amélioration des performances et de
            l'apparence de votre véhicule, et nous mettons toute notre expertise
            au service de votre satisfaction.
          </p>
        </div>
        <div className="separator">
          <img src={separator} alt="separator" />
        </div>
        <div className="Home__content--text">
          <h1>Notre expertise</h1>
          <p>
            Avec une expérience de plus d'une décennie dans le domaine de la
            réparation automobile, nous avons acquis un savoir-faire pour
            sublimer les voitures de nos clients. Que ce soit pour des
            modifications de moteur, des ajustements de suspension, des
            améliorations esthétiques ou toute autre demande de
            personnalisation, notre équipe qualifiée est prête à relever tous
            les défis.
          </p>
          <p>
            Nous nous engageons à offrir un service de qualité supérieure, une
            attention méticuleuse aux détails et une approche personnalisée pour
            chaque projet. Votre satisfaction est notre priorité, et nous
            travaillons sans relâche pour faire de votre vision une réalité.
          </p>
        </div>
        <div className="Home__content--text">
          <h1>Contactez-nous</h1>
          <p>
            Que vous soyez passionné d'automobile ou simplement à la recherche
            de performances optimales, nous sommes là pour répondre à vos
            besoins. N'hésitez pas à nous contacter pour discuter de vos projets
            et découvrir ce que nous pouvons accomplir ensemble.
          </p>
        </div>
        <div className="separator">
          <img src={separator} alt="separator" />
        </div>
        <div className="Home__content--map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2770.482477032369!2d5.8229933763447015!3d46.021520996081016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b7df94a7bf419%3A0xb74d417d423ea17b!2sGSI%20AutoMeca!5e0!3m2!1sen!2sfr!4v1711221113352!5m2!1sen!2sfr"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Home;
