import React, { useState } from 'react';
import header from '../assets/images/header.webp';
import { Link } from 'react-router-dom';

import './NavbarComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faLocationDot,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const NavbarComponent = () => {
  const [showMenu, setShowMenu] = useState(false);
  const togglePanel = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="header">
      <div className="header__banner">
        <img className="header__banner--image" src={header} alt="banneer" />
        <button className="header__banner--burger-menu">
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => setShowMenu(!showMenu)}
          />
        </button>
      </div>
      {showMenu ? (
        <div className="burger-menu-overlay" onClick={togglePanel}>
          <nav className="burger-menu-nav">
            <ul>
              <Link to="/">
                <li>Accueil</li>
              </Link>
              <Link to="/contact">
                <li>Demande de devis</li>
              </Link>
              <Link to="/CGU">
                <li>Mentions légales</li>
              </Link>
            </ul>
            <div className="burger-menu-nav__contact">
              <div>
                <p>
                  <a href="https://www.instagram.com/gsi_automeca/">
                    <strong>
                      <FontAwesomeIcon icon={faInstagram} />
                      Instagram:
                    </strong>
                    @gsi_automeca
                  </a>
                </p>
                <p>
                  <a href="https://www.facebook.com/p/GSI-AutoMeca-100072329517154">
                    <strong>
                      <FontAwesomeIcon icon={faFacebook} />
                      Facebook:
                    </strong>
                    GSI AutoMeca
                  </a>
                </p>
              </div>
              <div className="burger-menu-nav__contact--adress">
                <p>
                  <span>
                    <strong>
                      <FontAwesomeIcon icon={faLocationDot} />
                      Adresse:
                    </strong>
                  </span>{' '}
                  <span>GSI AutoMeca</span>
                </p>
                <p>302 Chem. ZA de la Culaz</p>
                <p>74910 Challonges</p>
              </div>
              <div className="burger-menu-nav__mail">
                <p>
                  <span>
                    <strong>
                      <FontAwesomeIcon icon={faEnvelope} />
                      Mail:
                    </strong>
                  </span>
                  <a href="mailto:gsi@automeca.biz">gsi@automeca.biz</a>
                </p>
              </div>
              {/* <p>contact@gsiautomeca.fr</p>  */}
            </div>
          </nav>
        </div>
      ) : null}
    </header>
  );
};

export default NavbarComponent;
