import './CGU.scss';

function CGU() {
  return (
    <div className="CGU">
      <h1>Mentions Légales</h1>

      <h2>Identification du professionnel</h2>
      <p>
        <strong>Nom de l'entreprise :</strong> GSI AUTOMECA
      </p>
      <p>
        <strong>Forme juridique :</strong> Société à responsabilité limitée
        (SARL)
      </p>
      <p>
        <strong>Adresse :</strong> 395 Route de Bellegarde, 74910 Challonges,
        France
      </p>
      <p>
        <strong>Numéro SIREN :</strong> 903 079 317
      </p>
      <p>
        <strong>Numéro SIRET :</strong> 903 079 317 00017
      </p>
      <p>
        <strong>Numéro TVA Intracommunautaire :</strong> FR27 903 079 317
      </p>
      <p>
        <strong>Code APE :</strong> 4520A - Entretien et réparation de véhicules
        automobiles légers
      </p>
      <p>
        <strong>Numéro de téléphone :</strong> 09 70 51 95 85
      </p>
      <p>
        <strong>Adresse email :</strong> contact@gsiautomeca.fr
      </p>

      <h2>Hébergeur du site</h2>
      <p>
        <strong>Nom de l'hébergeur :</strong> OVH SAS
      </p>
      <p>
        <strong>Adresse de l'hébergeur :</strong> 2 rue Kellermann, 59100
        Roubaix, France
      </p>
      <p>
        <strong>RCS Lille Métropole :</strong> 424 761 419 00045
      </p>
      <p>
        <strong>Code APE :</strong> 2620Z
      </p>
      <p>
        <strong>Numéro TVA Intracommunautaire :</strong> FR 22 424 761 419
      </p>
      <p>
        <strong>Capital social :</strong> 50 000 000 €
      </p>
      <p>
        <strong>Directeur de la publication :</strong> Michel Paulin
      </p>

      <h2>Traitement des données personnelles</h2>
      <p>
        Les données personnelles collectées sur ce site sont destinées à la
        gestion des rendez-vous et à la journalisation des incidents. Elles sont
        collectées via un formulaire de contact. En accord avec le Règlement
        Général sur la Protection des Données (RGPD), nous nous engageons à
        assurer la sécurité et la confidentialité de vos données personnelles.
      </p>

      <p>
        <strong>Responsable du traitement des données personnelles :</strong>
      </p>
      <p>
        <strong>Identité :</strong> GSI AUTOMECA
      </p>
      <p>
        <strong>Coordonnées :</strong> contact@gsiautomeca.fr
      </p>

      <p>
        <strong>Finalité du traitement :</strong>
      </p>
      <p>
        Les données collectées sont utilisées pour organiser des rendez-vous et
        à la journalisation des incidents.
      </p>

      <p>
        <strong>Base légale du traitement :</strong>
      </p>
      <p>Consentement de l'utilisateur.</p>

      <p>
        <strong>
          Caractère obligatoire ou facultatif du recueil de données personnelles
          :
        </strong>
      </p>
      <p>
        Les données marquées par un astérisque (*) dans nos formulaires sont
        obligatoires. En cas de non-fourniture, nous ne pourrons pas traiter
        votre demande.
      </p>

      <p>
        <strong>Destinataires des données personnelles :</strong>
      </p>
      <p>
        Les données personnelles collectées sont uniquement destinées à GSI
        AUTOMECA.
      </p>

      <p>
        <strong>Durée de conservation des données personnelles :</strong>
      </p>
      <p>
        Les données personnelles seront conservées pendant la durée nécessaire à
        la gestion de nos services.
      </p>

      <p>
        <strong>Droits de l'utilisateur :</strong>
      </p>
      <p>
        Conformément à la législation en vigueur, vous disposez des droits
        suivants : accès, rectification, effacement, opposition, limitation du
        traitement et portabilité de vos données personnelles. Vous pouvez
        exercer ces droits en nous contactant à l'adresse suivante :
        contact@gsiautomeca.fr.
      </p>

      <p>
        <strong>Réclamation auprès de la CNIL :</strong>
      </p>
      <p>
        Vous avez le droit d'introduire une réclamation auprès de la Commission
        Nationale de l'Informatique et des Libertés (CNIL) en cas de non-respect
        de vos droits.
      </p>

      <p>
        <strong>
          Transfert des données personnelles hors de l'Union européenne :
        </strong>
      </p>
      <p>
        Aucun transfert de données personnelles hors de l'Union européenne n'est
        effectué.
      </p>

      <h2>Utilisation de cookies</h2>
      <p>Notre site n'utilise pas de cookies.</p>

      <h2>Pour toute question ou demande d'assistance</h2>
      <p>
        <strong>Contactez-nous à :</strong> contact@gsiautomeca.fr
      </p>
      <p>
        <strong>Ou appelez-nous au :</strong> 09 70 51 95 85
      </p>
    </div>
  );
}

export default CGU;
