import './Contact.scss';
import React, { useState } from 'react';
import Modal from 'react-modal';

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const [selectedService, setSelectedService] = useState('service1');
  const [errorTelephone, setErrorTelephone] = useState(false);
  const [errorMail, setErrorMail] = useState(false);

  const openModal = (title, message) => {
    setModalContent({ title, message });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (modalContent.title === 'Succès') {
      window.location.href = '/';
    }
  };

  const handleServiceChange = event => {
    setSelectedService(event.target.value);
  };

  const logIncompleteSubmission = (data) => {
    console.log(data)
    var json = JSON.stringify(data);
    console.log(json)
    fetch('https://www.gsiautomeca.fr/data/log_incomplete.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: json,
    })
      .then(response => response.json())
      .then(data => {
        console.log('Incomplete submission logged:', data);
      })
      .catch(error => {
        console.error('Error logging incomplete submission:', error);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    // Toujours enregistrer la tentative de soumission
    logIncompleteSubmission(data);

    // Validation supplémentaire côté client
    const requiredFields = ['name', 'firstname', 'email', 'phone', 'service', 'message'];
    if (selectedService === 'service1') {
      requiredFields.push('immatriculation');
    }

    const emptyFields = requiredFields.filter(field => !data[field]);

    if (emptyFields.length > 0) {
      openModal('Erreur', 'Veuillez remplir tous les champs obligatoires.');
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(data['email'])) {
      openModal('Erreur', 'Adresse e-mail invalide.');
      return;
    }

    const phonePattern = /^0[1-9]([-. ]?[0-9]{2}){4}$/;
    if (!phonePattern.test(data['phone'])) {
      openModal('Erreur', 'Numéro de téléphone invalide.');
      return;
    }

    // Envoyer les données au backend
    setIsLoading(true);
    fetch('https://www.gsiautomeca.fr/data/mail.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data.status === 'success') {
          openModal('Succès', data.message);
        } else {
          openModal('Erreur', data.message);
        }
      })
      .catch(error => {
        setIsLoading(false);
        openModal('Erreur', "Une erreur s'est produite lors de l'envoi de votre demande.");
      });
  };

  const handleTelephone = event => {
    const pattern = /^0[1-9]([-. ]?[0-9]{2}){4}$/;
    if (pattern.test(event.target.value)) {
      setErrorTelephone(false);
    } else {
      setErrorTelephone(true);
    }
  };

  const handleEmail = event => {
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (pattern.test(event.target.value)) {
      setErrorMail(false);
    } else {
      setErrorMail(true);
    }
  };

  return (
    <div className="Contact">
      <h1>Demande de devis</h1>
      <form className="form-quotation" onSubmit={handleSubmit}>
        <label htmlFor="name">Nom:*</label>
        <input type="text" id="name" name="name" required />
        <label htmlFor="firstname">Prénom:*</label>
        <input type="text" id="firstname" name="firstname" required />
        <label htmlFor="email">Email:*</label>
        {errorMail && <p className="error">Format d'email incorrect</p>}
        <input
          type="email"
          id="email"
          name="email"
          required
          onChange={handleEmail}
        />
        <label htmlFor="phone">Téléphone:*</label>
        {errorTelephone && <p className="error">Format de téléphone incorrect</p>}
        <input
          type="tel"
          id="phone"
          name="phone"
          required
          onChange={handleTelephone}
        />

        {selectedService === 'service1' ? (
          <>
            <label htmlFor="immatriculation">Immatriculation du véhicule:*</label>
            <input type="text" id="immatriculation" name="immatriculation" required />
          </>
        ) : (
          <>
            <label htmlFor="immatriculation">Immatriculation du véhicule:</label>
            <input type="text" id="immatriculation" name="immatriculation" />
          </>
        )}
        <label htmlFor="service">Type de demande:*</label>
        <select name="service" id="service" onChange={handleServiceChange}>
          <option value="service1">Entretien/Diagnostics/réparation</option>
          <option value="service2">Fabrication/Préparation/pièces perf</option>
          <option value="service3">Autres demandes</option>
        </select>

        <label htmlFor="message">Motif:*</label>
        <textarea id="message" name="message" required></textarea>
        <button type="submit">Confirmer la demande de devis</button>
      </form>
      {isLoading && <div className="loader">Chargement...</div>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
      >
        <h2>{modalContent.title}</h2>
        <div>{modalContent.message}</div>
        <button onClick={closeModal}>OK</button>
      </Modal>
    </div>
  );
}

export default Contact;
